exports.components = {
  "component---src-pages-aktuelt-js": () => import("./../../../src/pages/aktuelt.js" /* webpackChunkName: "component---src-pages-aktuelt-js" */),
  "component---src-pages-fagstoff-js": () => import("./../../../src/pages/fagstoff.js" /* webpackChunkName: "component---src-pages-fagstoff-js" */),
  "component---src-pages-firdakilen-js": () => import("./../../../src/pages/firdakilen.js" /* webpackChunkName: "component---src-pages-firdakilen-js" */),
  "component---src-pages-fysio-og-manuellterapi-js": () => import("./../../../src/pages/fysio-og-manuellterapi.js" /* webpackChunkName: "component---src-pages-fysio-og-manuellterapi-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-nakke-diagnostisering-js": () => import("./../../../src/pages/nakke_diagnostisering.js" /* webpackChunkName: "component---src-pages-nakke-diagnostisering-js" */),
  "component---src-pages-nakke-rehabilitering-js": () => import("./../../../src/pages/nakke_rehabilitering.js" /* webpackChunkName: "component---src-pages-nakke-rehabilitering-js" */),
  "component---src-pages-om-oss-js": () => import("./../../../src/pages/om_oss.js" /* webpackChunkName: "component---src-pages-om-oss-js" */),
  "component---src-pages-opningstider-js": () => import("./../../../src/pages/opningstider.js" /* webpackChunkName: "component---src-pages-opningstider-js" */),
  "component---src-pages-overnatting-js": () => import("./../../../src/pages/overnatting.js" /* webpackChunkName: "component---src-pages-overnatting-js" */),
  "component---src-pages-pasienthistorier-js": () => import("./../../../src/pages/pasienthistorier.js" /* webpackChunkName: "component---src-pages-pasienthistorier-js" */),
  "component---src-pages-prisar-js": () => import("./../../../src/pages/prisar.js" /* webpackChunkName: "component---src-pages-prisar-js" */),
  "component---src-pages-reise-til-sandane-js": () => import("./../../../src/pages/reise_til_sandane.js" /* webpackChunkName: "component---src-pages-reise-til-sandane-js" */),
  "component---src-pages-sandbox-js": () => import("./../../../src/pages/sandbox.js" /* webpackChunkName: "component---src-pages-sandbox-js" */),
  "component---src-pages-trening-js": () => import("./../../../src/pages/trening.js" /* webpackChunkName: "component---src-pages-trening-js" */)
}

